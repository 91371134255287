<header>
    <div class="content">
        <h1 [routerLink]="['']">Utils</h1>
        <nav>
            <ul>
                <li>
                    <button [routerLink]="['identification']">Identificación</button>
                </li>
                <li>
                    <button [routerLink]="['codes']">Códigos</button>
                </li>
                <li>
                    <button [routerLink]="['passwords']">Contraseñas</button>
                </li>
                <!--<li>
                    <button [routerLink]="['texto']">Texto</button>
                </li>-->
            </ul>
        </nav>
    </div>
</header>
<main>
    <router-outlet></router-outlet>
    <router-outlet name="quick"></router-outlet>
    <router-outlet name="help"></router-outlet>
    <router-outlet name="cookies"></router-outlet>
</main>
<footer>
    <div class="content">
        <div class="legal-container">
            <p>{{ title }} &copy; {{ currentYear }}</p>
            <a class="cookies-policy" [routerLink]="['cookies']">Política de cookies</a>
        </div>
        <div class="author-container">
            <a href="https://www.linkedin.com/in/joseemiliogutierrezmoran" target="blank" class="author">José Emilio Gutiérrez</a>
            <a href="https://paypal.me/joseemiliogutierrez" class="fab fa-paypal" target="blank">
                <p>Contribuir al proyecto</p>
            </a>
        </div>
    </div>
</footer>