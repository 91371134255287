import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { CIF } from '../../models/cif';
import { Province } from '../../models/province';
import { Society } from '../../models/society';
import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-custom-cif-generator',
  templateUrl: './custom-cif-generator.component.html',
  styleUrls: ['./custom-cif-generator.component.scss']
})
export class CustomCifGeneratorComponent implements OnInit {

  societies: Array<Society>;
  provinces: Array<Province>;
  cif: CIF;
  societySelected: Society;
  provinceSelected: Province;

  constructor(private readonly idService: IdentityDocumentsService, private readonly utils: UtilsService) {
    this.societies = this.idService.societies;
    this.provinces = this.idService.provinces;

    this.provinceSelected = this.provinces[0];
    this.societySelected = this.societies[0];
  }

  ngOnInit(): void {
    this.generate();
  }

  generate(): void {
    this.cif = this.idService.generateCustomCIF(this.societySelected, this.provinceSelected);
  }

  copy(value: string, event): void {
    this.utils.copyToClipboard(value, event);
  }

}
