import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Province } from '../../models/province';
import { Society } from '../../models/society';
import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-cif-check-digit-checker',
  templateUrl: './cif-check-digit-checker.component.html',
  styleUrls: ['./cif-check-digit-checker.component.scss']
})
export class CifCheckDigitCheckerComponent implements OnInit {

  cif: string;
  digitControl: string;
  province: Province;
  society: Society;

  constructor(private readonly id: IdentityDocumentsService, private readonly snackBar: MatSnackBar) {
    this.cif = '';
    this.digitControl = '';
  }

  ngOnInit(): void {
  }

  calculate(): void {
    if (this.id.isValidCIF(this.cif)) {
      const provinceIdentifier = Number(this.cif.substr(1, 2));
      const societyLetter = this.cif.substr(0, 1);

      this.cif = this.cif + this.id.calculateCIFDigitControl(societyLetter, provinceIdentifier,
      Number(this.cif.substr(3, 5)));

      this.society = this.id.findSocietyByLetter(societyLetter);
      this.province = this.id.findProvinceByIdentifier(provinceIdentifier);
      console.log(this.society, this.province);
    } else {
      this.openSnackBar('El CIF introducido debe estár compuesto por los primeros 7 dígitos.');
    }
  }

  copy(event): void {
    event.target.innerHTML = '¡Copiado!';
    setTimeout(() => {
      event.target.innerHTML = 'Copiar';
    }, 1000);

    navigator.clipboard.writeText(this.cif);
  }

  clear() {
    this.province = undefined;
    this.society = undefined;
    this.cif = '';
    this.digitControl = '';
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }

}
