<div class="container">
    <div class="title-container">
        <i class="fas fa-id-card"></i>
        <h1 class="title">NIE</h1>
    </div>

    <div class="generator-container">
        <div class="nif-container">
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(nie.nie + nie.controlDigit, $event)">{{ nie.nie + nie.controlDigit }}</button>
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(nie.nie, $event)">{{ nie.nie }}</button>
        </div>
    </div>
    <button class="generate" (click)="generate()">Generar</button>
</div>