import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { NIF } from '../../models/nif';
import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-nif-generator',
  templateUrl: './nif-generator.component.html',
  styleUrls: ['./nif-generator.component.scss']
})
export class NifGeneratorComponent implements OnInit {

  nif: NIF;

  constructor(private readonly idService: IdentityDocumentsService, private readonly utils: UtilsService) {
    this.generate();
  }

  ngOnInit(): void {
  }

  generate(): void {
    this.nif = this.idService.generateNIF();
  }

  copy(value: any, event): void {
    this.utils.copyToClipboard(value, event);
  }

}
