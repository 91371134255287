<div class="container">
    <div class="title-container">
        <i class="fas fa-calculator"></i>
        <h1 class="title">Dígito de control NIF/NIE</h1>
    </div>
    <div class="generator-container">
        <div class="nif-container">
            <input type="text" class="cif" placeholder="Ej. 68162055" [(ngModel)]="value">
            <div class="buttons-container">
                <i *ngIf="value !== undefined" class="fas fa-broom" (click)="clear()" mat-raised-button matTooltip="Limpiar"></i>
                <p (click)="copy($event)">Copiar</p>
            </div>
        </div>
    </div>
    <button class="generate" (click)="calculate()">Calcular</button>
</div>