import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-cif-generator',
  templateUrl: './cif-generator.component.html',
  styleUrls: ['./cif-generator.component.scss']
})
export class CifGeneratorComponent implements OnInit {

  cif: any;

  constructor(private readonly idService: IdentityDocumentsService, private readonly utils: UtilsService) {
  }

  ngOnInit(): void {
    this.generate();
  }

  generate(): void {
    this.cif = this.idService.generateCIF();
  }

  copy(value: string, event): void {
    this.utils.copyToClipboard(value, event);
  }

}
