<div class="container">
    <div class="title-container">
        <i class="fas fa-id-card"></i>
        <h1 class="title">CIF Personalizado</h1>
    </div>
    <div class="generator-container">
        <div class="nif-container">
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(cif?.cif + cif?.controlDigit, $event)">{{ cif?.cif + cif?.controlDigit }}</button>
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(cif?.cif, $event)">{{ cif?.cif }}</button>
        </div>
        <div class="details-container">
            <select class="society-selector" [(ngModel)]="provinceSelected">
                <option *ngFor="let province of provinces" [ngValue]="province">{{ province.name }}</option>
            </select>
            <select class="society-selector bold" [(ngModel)]="societySelected">
                <option *ngFor="let society of societies" [ngValue]="society">{{ society.letter + ': ' + society.name }}</option>
            </select>
        </div>
    </div>
    <button class="generate" (click)="generate()">Generar</button>
</div>