<div class="container">
    <div class="content">
        <div class="title-container">
            <i class="fas fa-id-card"></i>
            <h1 class="title">NIF</h1>
        </div>
        <p class="description">El Número de Identificación Fiscal es la manera de identificación tributaria utilizada en España para las personas físicas.​</p>
        <div class="generator-container">
            <div class="nif-container">
                <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(lpad(NIFNumber) + NIFLetter, $event)">{{ lpad(NIFNumber) + NIFLetter }}</button>
                <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(lpad(NIFNumber), $event)">{{ lpad(NIFNumber) }}</button>
            </div>
            <button class="generate" (click)="generate()">Generar</button>
        </div>
    </div>
</div>