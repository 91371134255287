import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';

import { WarningCookiesPolicyComponent } from './components/warning-cookies-policy/warning-cookies-policy.component';


const routes: Routes = [
  {
    path: 'codes',
    loadChildren: () => import('./codes/codes.module').then(m => m.CodesModule)
  },
  {
    path: 'cookies',
    component: CookiesPolicyComponent
  },
  {
    path: '',
    outlet: 'cookies',
    component: WarningCookiesPolicyComponent
  },
  {
    path: 'passwords',
    loadChildren: () => import('./passwords/passwords.module').then(m => m.PasswordsModule)
  }
  /*{
    path: 'texto',
    loadChildren: () => import('./text/text.module').then(m => m.TextModule)
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
