import { stringify } from '@angular/compiler/src/util';
import { Injectable, ɵConsole } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { NIF } from '../models/nif';
import { NIE } from '../models/nie';
import { CIF } from '../models/cif';
import { Society } from '../models/society';
import { Province } from '../models/province';
import { FirstNIELetter } from '../models/first-nie-letter';

import * as provinces from 'src/assets/json/provincial-identifiers.json';
import * as societies from 'src/assets/json/societies.json';

@Injectable({
  providedIn: 'root'
})
export class IdentityDocumentsService {

  private NIF_LETTERS = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
  private CIF_LETTERS = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  private FIRST_NIE_LETTERS = [{letter: 'X', plus: 0}, {letter: 'Y', plus: 10000000}, {letter: 'Z', plus: 20000000}];

  constructor(private readonly utils: UtilsService) { }

  generateNIF(): NIF {
    const numbers: string = this.utils.lpad('0', 8, this.utils.generateRandomNumber(0, 99999999).toString());
    const controlDigit: string = this.calculateDigitControl(Number(numbers));

    return {
      numbers,
      controlDigit,
      nif: numbers + controlDigit
    } as NIF;
  }

  generateNIE(): NIE {
    const initialLetter: FirstNIELetter = this.generateFirstLetter();
    const numbers: string = this.utils.lpad('0', 7, this.utils.generateRandomNumber(0, 9999999).toString());
    const controlDigit: string = this.calculateDigitControl(Number(numbers) + initialLetter.plus);

    return {
      initialLetter: initialLetter.letter,
      numbers,
      controlDigit,
      nie: initialLetter.letter + numbers
    } as NIE;
  }

  private generateFirstLetter(): FirstNIELetter {
    return this.FIRST_NIE_LETTERS[this.utils.generateRandomNumber(0, 2)];
  }

  calculateDigitControl(documentNumber: number): string {
    return this.NIF_LETTERS[documentNumber % 23];
  }

  calculateNIEDigitControl(value: string): string {
    const plus = this.findPlusFirstNIELetter(value.substr(0, 1));
    return this.calculateDigitControl(Number(value.substr(1, 7)) + plus);
  }

  findPlusFirstNIELetter(letter: string): number {
    return this.FIRST_NIE_LETTERS.find(f => f.letter === letter).plus;
  }

  isNIE(value: string): boolean {
    return RegExp('^[xyzXYZ]{1}\\d{7}$').test(value);
  }

  isNIF(value: string): boolean {
    return RegExp('^\\d{8}$').test(value);
  }

  findProvinceByIdentifier(identifier: number): Province {
    return provinces.default.find((p: Province) => p.identifiers.includes(identifier));
  }

  findSocietyByLetter(letter: string): Society {
    return societies.default.find((p: Society) => p.letter === letter);
  }

  generateCIF(): CIF {
    const society: Society = societies.default[this.utils.generateRandomNumber(0, societies.default.length - 1)];
    const province: Province = provinces.default[this.utils.generateRandomNumber(0, provinces.default.length - 1)];
    const provinceIdentifier: number = province.identifiers[this.utils.generateRandomNumber(0, province.identifiers.length - 1)];
    const numbers: number = this.utils.generateRandomNumber(10000, 99999);
    const controlDigit: string = this.calculateCIFDigitControl(society.letter, provinceIdentifier, numbers);

    return {
      society: society,
      provinceIdentifier: provinceIdentifier,
      province: province,
      numbers: numbers,
      controlDigit: controlDigit,
      cif: society.letter + this.utils.lpad('0', 2, provinceIdentifier.toString()) + numbers
    } as CIF;
  }

  generateCustomCIF(society: Society, province: Province): CIF {
    const numbers: number = this.utils.generateRandomNumber(10000, 99999);
    const identifier: number = province.identifiers[this.utils.generateRandomNumber(0, province.identifiers.length - 1)];
    const controlDigit: string = this.calculateCIFDigitControl(society.letter, identifier, numbers);

    return {
      society: society,
      provinceIdentifier: identifier,
      province: province,
      numbers: numbers,
      controlDigit: controlDigit,
      cif: society.letter + this.utils.lpad('0', 2, identifier.toString()) + numbers
    } as CIF;
  }

  calculateCIFDigitControl(societyLetter: string, provinceIdentifier: number, numbers: number): string {
    const digits = this.utils.lpad('0', 7, provinceIdentifier.toString() + numbers.toString());
    let totalEvenPosition = 0;
    let totalOddPosition = 0;

    for (let i = 0, pos = 1; i < digits.toString().length; i++, pos++) {
      const digit = Number(digits.toString().charAt(i));
      if (this.utils.isOdd(pos)) {
        totalOddPosition += (digit * 2).toString().split('').reduce((r, n) => r + Number(n), 0);
      } else {
        totalEvenPosition += digit;
      }
    }

    const total = totalEvenPosition + totalOddPosition;
    const unit = Number(total.toString().charAt(total.toString().length - 1));
    const controlDigit = unit !== 0 ? 10 - unit : unit;

    return this.isCIFControlDigitLetter(societyLetter, provinceIdentifier) ? this.CIF_LETTERS[controlDigit] : String(controlDigit);
  }

  isValidCIF(value: string): boolean {
    return RegExp('^[abcdefghjnpqrsuvwABCDEFGHJNPQRSUVW]{1}\\d{7}$').test(value);
  }

  private   isCIFControlDigitLetter(societyLetter: string, provinceIdentifier: number): boolean {
    const LETTERS = 'PQRSW';
    return LETTERS.includes(societyLetter);
  }

  get provinces(): Array<any> {
    return provinces.default;
  }

  get societies(): Array<any> {
    return societies.default;
  }

  get firstNIELetters(): any  {
    return this.FIRST_NIE_LETTERS;
  }
}
