import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  generateRandomNumber(min: number, max: number): number {
    return Math.round(Math.random() * (max - min) + min);
  }

  lpad(fillString: string, valueLength: number, value: string): string {
    while (value.toString().length < valueLength) {
      value = fillString + value;
    }
    return value;
  }

  isOdd(value: number): boolean {
    return Boolean(value % 2);
  }

  copyToClipboard(value: any, event): void {
    event.target.style.backgroundColor = '#8fbc8f6e';
    setTimeout(() => {
      event.target.style = '';
    }, 300);

    navigator.clipboard.writeText(value.toString());
  }
}
