import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/utils.service';

import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-quick-dni-generator',
  templateUrl: './quick-nif-generator.component.html',
  styleUrls: ['./quick-nif-generator.component.scss']
})
export class QuickDniGeneratorComponent implements OnInit {

  NIFNumber: number;
  NIFLetter: string;

  constructor(private readonly idService: IdentityDocumentsService, private readonly utils: UtilsService, private readonly titleService: Title) {
    this.generate();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Utils');
  }

  generate(): void {
    this.NIFNumber = this.utils.generateRandomNumber(0, 99999999);
    this.NIFLetter = this.idService.calculateDigitControl(this.NIFNumber);
  }

  lpad(value: string | number): string {
    return this.utils.lpad('0', 8, value.toString());
  }

  copy(value: string, event): void {
    this.utils.copyToClipboard(value, event);
  }

}
