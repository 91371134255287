import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-cookies-policy',
  templateUrl: './warning-cookies-policy.component.html',
  styleUrls: ['./warning-cookies-policy.component.scss']
})
export class WarningCookiesPolicyComponent implements OnInit {

  private localStorageCookies = 'accept-utils.es-cookies';
  open: boolean;

  constructor() {
    this.open = localStorage.getItem(this.localStorageCookies) == null;
  }

  ngOnInit(): void {
  }

  close() {
    this.open = false;
  }

  accept() {
    localStorage.setItem(this.localStorageCookies, 'true');
    this.open = false;
  }

}
