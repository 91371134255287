import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-nif-nie-check-digit-checker',
  templateUrl: './nif-nie-check-digit-checker.component.html',
  styleUrls: ['./nif-nie-check-digit-checker.component.scss']
})
export class NifNieCheckDigitCheckerComponent implements OnInit {

  value: string;
  digitControl: string;

  constructor(private readonly id: IdentityDocumentsService, private readonly snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  calculate(): void {
    if (this.id.isNIF(this.value)) {
      this.value = this.value + this.id.calculateDigitControl(Number(this.value));
    } else if (this.id.isNIE(this.value)) {
      this.value = this.value + this.id.calculateNIEDigitControl(this.value);
    } else {
      this.openSnackBar('El NIF/NIE introducido debe estár compuesto por los primeros 7 dígitos.');
    }
  }

  clear() {
    this.value = undefined;
    this.digitControl = undefined;
  }

  copy(event): void {
    console.log(event);
    event.target.innerHTML = '¡Copiado!';
    setTimeout(() => {
      event.target.innerHTML = 'Copiar';
    }, 1000);

    navigator.clipboard.writeText(this.value);
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000,
      horizontalPosition: 'left'
    });
  }

}
