<div class="title-container">
    <h1 class="title content">Identificación</h1>
</div>
<div class="container content">
    <div class="module-container">
        <app-nif-generator></app-nif-generator>
        <app-nie-generator></app-nie-generator>
        <app-nif-nie-check-digit-checker></app-nif-nie-check-digit-checker>
    </div>
    <div class="module-container">
        <app-cif-generator></app-cif-generator>
        <app-custom-cif-generator></app-custom-cif-generator>
        <app-cif-check-digit-checker></app-cif-check-digit-checker>
    </div>
</div>