import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IdentityDocumentsRoutingModule } from './identity-documents-routing.module';
import { QuickDniGeneratorComponent } from './components/quick-nif-generator/quick-nif-generator.component';

import { MatTooltipModule } from '@angular/material/tooltip';

import { UtilsService } from './../services/utils.service';
import { IdentityDocumentsComponent } from './components/identity-documents/identity-documents.component';
import { NifGeneratorComponent } from './components/nif-generator/nif-generator.component';
import { NieGeneratorComponent } from './components/nie-generator/nie-generator.component';
import { CifGeneratorComponent } from './components/cif-generator/cif-generator.component';
import { CifCheckDigitCheckerComponent } from './components/cif-check-digit-checker/cif-check-digit-checker.component';
import { CustomCifGeneratorComponent } from './components/custom-cif-generator/custom-cif-generator.component';
import { FormsModule } from '@angular/forms';

import {MatSelectModule} from '@angular/material/select';
import { NifNieCheckDigitCheckerComponent } from './components/nif-nie-check-digit-checker/nif-nie-check-digit-checker.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
  declarations: [QuickDniGeneratorComponent,
                IdentityDocumentsComponent,
                NifGeneratorComponent,
                NieGeneratorComponent,
                CifGeneratorComponent,
                CifCheckDigitCheckerComponent,
                CustomCifGeneratorComponent,
                NifNieCheckDigitCheckerComponent
  ],
  imports: [
    CommonModule,
    IdentityDocumentsRoutingModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    MatSnackBarModule
  ],
  providers: [
    UtilsService
  ]
})
export class IdentityDocumentsModule { }
