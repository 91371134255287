<div class="container">
    <div class="title-container">
        <i class="fas fa-id-card"></i>
        <h1 class="title">NIF</h1>
    </div>

    <div class="generator-container">
        <div class="nif-container">
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(nif.nif, $event)">{{ nif.nif }}</button>
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(nif.numbers, $event)">{{ nif.numbers }}</button>
        </div>
    </div>
    <button class="generate" (click)="generate()">Generar</button>
</div>