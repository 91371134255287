import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { NIE } from '../../models/nie';
import { IdentityDocumentsService } from '../../services/identity-documents.service';

@Component({
  selector: 'app-nie-generator',
  templateUrl: './nie-generator.component.html',
  styleUrls: ['./nie-generator.component.scss']
})
export class NieGeneratorComponent implements OnInit {

  nie: NIE;

  constructor(private readonly idService: IdentityDocumentsService, private readonly utils: UtilsService) {
    this.generate();
  }

  ngOnInit(): void {
  }

  generate(): void {
    this.nie = this.idService.generateNIE();
  }

  copy(value: string, event): void {
    this.utils.copyToClipboard(value, event);
  }

}
