<div class="container">
    <div class="title-container">
        <i class="fas fa-calculator"></i>
        <h1 class="title">Dígito de control CIF</h1>
    </div>
    <div class="generator-container">
        <div class="nif-container">
            <input type="text" class="cif" placeholder="Ej. F3349133" [(ngModel)]="cif">
            <div class="buttons-container">
                <i *ngIf="society || province !== undefined" class="fas fa-broom" (click)="clear()" mat-raised-button matTooltip="Limpiar"></i>
                <p (click)="copy($event)">Copiar</p>
            </div>
        </div>
        <p class="detail">{{ province?.name }}</p>
        <p class="detail bold">{{ society?(society?.letter + ': ' + society?.name):'' }}</p>
    </div>
    <button class="generate" (click)="calculate()">Calcular</button>
</div>