import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTooltipModule } from '@angular/material/tooltip';

import { IdentityDocumentsModule } from './identity-documents/identity-documents.module';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { WarningCookiesPolicyComponent } from './components/warning-cookies-policy/warning-cookies-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    CookiesPolicyComponent,
    WarningCookiesPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    IdentityDocumentsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
