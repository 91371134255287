<div class="title-container">
    <h1 class="title content">Política de cookies</h1>
</div>
<div class="container content">
    <p>
        En esta web se utilizan cookies de terceros para que podamos obtener estadísticas de los usuarios. Puedes evitar la descarga de cookies a través de la configuración de tu navegador, evitando que las cookies se almacenen en su dispositivo. Como propietario
        de este sitio web, te comunico que no utilizamos ninguna información personal procedente de cookies, tan sólo realizamos estadísticas generales de visitas que no suponen ninguna información personal. Es muy importante que leas la presente política
        de cookies y comprendas que, si continúas navegando, consideraremos que aceptas su uso. Según los términos incluidos en el artículo 22.2 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, si continúas navegando,
        estarás prestando tu consentimiento para el empleo de los referidos mecanismos.
    </p>
    <h2>Entidad Responsable</h2>
    <p>La entidad responsable de la recogida, procesamiento y utilización de tus datos personales, en el sentido establecido por la Ley de Protección de Datos Personales es la página www.utils.es, propiedad de José Emilio Gutiérrez Morán.</p>

    <h2>¿Qué son las cookies?</h2>
    <p>Las cookies son un conjunto de datos que un servidor deposita en el navegador del usuario para recoger la información de registro estándar de Internet y la información del comportamiento de los visitantes en un sitio web. Es decir, se trata de pequeños
        archivos de texto que quedan almacenados en el disco duro del ordenador y que sirven para identificar al usuario cuando se conecta nuevamente al sitio web. Su objetivo es registrar la visita del usuario y guardar cierta información. Su uso es
        común y frecuente en la web ya que permite a las páginas funcionar de manera más eficiente y conseguir una mayor personalización y análisis sobre el comportamiento del usuario.</p>

    <h2>¿Qué tipos de cookies son utilizadas?</h2>
    <p>Las cookies utilizadas en nuestro sitio web son de terceros y nos permiten analizar la actividad que lleva a cabo, con el objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y personalizada.</p>
    <p>Cada vez que un usuario visita un servicio, una herramienta de un proveedor externo genera una cookie analítica en el ordenador del usuario. Esta cookie que sólo se genera en la visita, servirá en próximas visitas a los servicios de José Emilio Gutiérrez
        Morán para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:</p>
    <p>Permitir la identificación anónima de los usuarios navegantes a través de la cookie (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo. Identificar
        de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios Saber si el usuario que está accediendo es nuevo o repite visita.</p>
    <p>Importante: Salvo que el usuario decida registrarse en un servicio de José Emilio Gutiérrez Morán, la cookie nunca irá asociada a ningún dato de carácter personal que pueda identificarle. Dichas cookies sólo serán utilizadas con propósitos estadísticos
        que ayuden a la optimización de la experiencia de los usuarios en el sitio.</p>



    <h2>¿Cómo puedo deshabilitar las cookies en mi navegador?</h2>
    <p>Se pueden configurar los diferentes navegadores para avisar al usuario de la recepción de cookies y, si se desea, impedir su instalación en el equipo. Asimismo, el usuario puede revisar en su navegador qué cookies tiene instaladas y cuál es el plazo
        de caducidad de las mismas, pudiendo eliminarlas.

        <p>Para ampliar esta información consulte las instrucciones y manuales de su navegador:</p>

        <p>Para más información sobre la administración de las cookies en Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a></p>
        <p>Para más información sobre la administración de las cookies en Internet Explorer: <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions</a></p>
        <p>Para más información sobre la administración de las cookies en Mozilla Firefox: <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
        <p>Para más información sobre la administración de las cookies en Safari: <a href="http://www.apple.com/es/privacy/use-of-cookies/">http://www.apple.com/es/privacy/use-of-cookies/</a></p>
        <p>Para más información sobre la administración de las cookies en Opera: <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
        <p>Si desea dejar de ser seguido por Google Analytics visite: <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a></p>

        <h2>Para saber más sobre las cookies</h2>
        <p>Protección de datos de Google Analytics: <a href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a></p>
        <p>Cómo usa Google Analytics las cookies: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs</a></p>

        <h2>Actualizaciones y cambios en la política de privacidad/cookies</h2>
        <p>Las webs de José Emilio Gutiérrez Morán pueden modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección
            de Datos, por ello se aconseja a los usuarios que la visiten periódicamente. Cuando se produzcan cambios significativos en esta Política de Cookies, estos se comunicarán a los usuarios bien mediante la web o a través de correo electrónico
            a los usuarios registrados.</p>
</div>