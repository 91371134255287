<div class="container">
    <div class="title-container">
        <i class="fas fa-id-card"></i>
        <h1 class="title">CIF</h1>
    </div>

    <div class="generator-container">
        <div class="nif-container">
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(cif.cif + cif.controlDigit, $event)">{{ cif.cif + cif.controlDigit }}</button>
            <button class="nif" mat-raised-button matTooltip="Copiar" matTooltipPosition='right' (click)="copy(cif.cif, $event)">{{ cif.cif }}</button>
        </div>
        <div class="details-container">
            <p class="detail">{{ cif?.province.name }}</p>
            <p class="detail bold">{{ cif?.society.letter + ': ' + cif?.society.name }}</p>
        </div>
    </div>
    <button class="generate" (click)="generate()">Generar</button>
</div>