import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'utils.es';
  currentYear = moment().format('yyyy');
  open: boolean;

  constructor(private titleService: Title) {
    this.open = true;
  }

  ngOnInit(): void {
  }

}
