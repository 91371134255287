import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IdentityDocumentsComponent } from './components/identity-documents/identity-documents.component';

import { QuickDniGeneratorComponent } from './components/quick-nif-generator/quick-nif-generator.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    outlet: 'quick',
    component: QuickDniGeneratorComponent
  },
  {
    path: 'identification',
    component: IdentityDocumentsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IdentityDocumentsRoutingModule { }
